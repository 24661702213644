import React, { Component } from 'react';

import './ButtonReservation.scss';
import Content from '../Content';

export default class ButtonReservation extends Component {
  render() {
    const { label, phone, link } = Content.Reservations;

    return (
      <a className='ButtonReservation' href={link} onClick={(e) => {
        window.gtag('event', 'Click', {
          'event_category' : 'Reservation',
          'event_label' : 'Button',
        });
      }}>
        <div className='label'>{label}</div>
        <div className='phone'>{phone}</div>
      </a>
    );
  }
}
