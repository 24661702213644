import React, { Component } from 'react';
import { connect } from 'react-redux';

import './HomeReviews.scss';
import Review from './Review';
import Content from '../Content';
import Breakpoints from '../utils/Breakpoints';

class HomeReviews extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    // this binding
    this._getColumnDisplay = this._getColumnDisplay.bind(this);
    this._getGridDisplay = this._getGridDisplay.bind(this);
  }
  render() {
    const { breakpoint } = this.props;
    const { reviews, images } = Content.Home.Reviews;
    const isMobile = Breakpoints.isMobile(breakpoint);
    const items = (isMobile ? this._getColumnDisplay(images, reviews) : this._getGridDisplay(images, reviews));

    return (
      <div {...this.props} className='HomeReviews'>
        <div>
          {items}
        </div>
      </div>
    );
  }

  // Utils
  _getColumnDisplay(images, reviews) {
    let items = [];

    items.push(
      <div key={'image-0'} className="image">
        <img src={images[0].mobileSrc} alt={images[0].alt} />
      </div>
    );

    for (let i = 0; i < reviews.length && i < 4; i++) {
      items.push(
        <Review
          key={'rating-'+i}
          rating={reviews[i].rating}
          text={reviews[i].text}
        />
      );
    }

    items.push(
      <div key={'image-1'} className="image bottom">
        <img src={images[1].mobileSrc} alt={images[1].alt} />
      </div>
    );

    return items;
  }
  _getGridDisplay(images, reviews) {
    let i,
        temp = [],
        items = [],
        reorderedItems = [],
        cells = this.props.breakpoint === 'xl' ? 8 : 9;

    // if(this.props.breakpoint != 'xl') {
      for (i = 0; i < cells; i ++) {
        if (i % 2 === 1){ // odd
          let photoIndex = Math.floor(i/2);
          items.push(
            <div key={'image-'+i} className="image">
              <img src={images[photoIndex].desktopSrc} alt={images[photoIndex].alt} />
            </div>
          )
        }
        else { // even
          let reviewsIndex = Math.floor(i/2);
          items.push(
            <Review
              key={'review-'+i}
              rating={reviews[reviewsIndex].rating}
              text={reviews[reviewsIndex].text}
              secondReview={reviews[reviewsIndex].secondReview}
              secondRating={reviews[reviewsIndex].secondRating}
              secondText={reviews[reviewsIndex].secondText}
            />
          )
        }
      }
      if(this.props.breakpoint === 'xl') {
        temp = items.slice(4, items.length);
        temp.reverse();
        items.splice(4, items.length);

        reorderedItems = items.concat(temp);
        items = reorderedItems;
      }

    return items;
  }
}

// Redux Mapping
const mapStateToProps = (state) => ({
  breakpoint: state.AppReducer.breakpoint,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps,mapDispatchToProps)(HomeReviews)
