import React, { Component } from 'react'
import Glide, { Autoplay } from '@glidejs/glide/dist/glide.modular.esm'
import { connect } from 'react-redux'
import Breakpoints from '../utils/Breakpoints'

import './ImageCarousel.scss';

class ImageCarousel extends Component {
  static defaultProps = {
    slides: [],
    glideOptions: {
      type: 'carousel',
      startAt: 0,
      autoplay: 4000,
      animationDuration: 600,
      animationTimingFunc: 'ease-in-out',
      gap: 0,
      focusAt: "center",
      rewind: 'infinite',
    },
  }

  // Lifecycle
  constructor(props) {
    super(props);
    this.state = {};
    this.glide = null;
    this.glideOptions = props.glideOptions;
  }
  componentDidMount() {
    this.glide = new Glide(`.ImageCarouselGlide`, this.glideOptions);
    this.glide.mount({ Autoplay });
  }
  render() {
    const { 
      slides, 
      breakpoint,
    } = this.props;
    const isMobile = !Breakpoints.isDesktop(breakpoint);

    return (
      <div className='ImageCarousel'>
        <div className='ImageCarouselGlide'>
          <div data-glide-el="track" className="glide__track">
            <ul className="glide__slides">
              {slides.map((slide, index) => (
                <li key={"slide-" + index} className='glide__slide'>
                  <img src={(isMobile ? slide.mobileSrc : slide.desktopSrc)}
                    alt={slide.alt} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

// Redux Mapping
const mapStateToProps = (state) => ({
  breakpoint: state.AppReducer.breakpoint,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps,mapDispatchToProps)(ImageCarousel);
