import React, { Component } from 'react';
import { connect } from 'react-redux';

import './MobileImage.scss';
import Breakpoints from '../utils/Breakpoints';

class MobileImage extends Component {
  static defaultProps = {
    image: {
      src: '/someimage.jpg',
      alt: 'Alt text here',
    },
  }

  render() {
    const { image, breakpoint } = this.props;

    return (
      <div>
        {Breakpoints.isMobile(breakpoint) &&
        <div {...this.props} className='MobileImage'>
          <img src={image.src} alt={image.alt} />
        </div>
        }
      </div>
    );
  }
}

// Redux Mapping
const mapStateToProps = (state) => ({
  breakpoint: state.AppReducer.breakpoint,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps,mapDispatchToProps)(MobileImage)
