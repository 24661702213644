import React, { Component } from 'react';

import './ButtonReservationBar.scss';
import ButtonReservation from './ButtonReservation';

export default class ButtonReservationBar extends Component {
  static defaultProps = {
  }

  render() {
    return (
      <div className='ButtonReservationBar'>
        <ButtonReservation />
      </div>
    );
  }
}
