import React from 'react'
import ImgMenuIconRaw from './images/menu/rawfish.svg'
import ImgMenuIconVegetarian from './images/menu/vegetarian.svg'
import ImgMenuIconSpicy from './images/menu/spicy.svg'
// import ImgMenuIconNew from './images/menu/new.svg'
import ImgMenuIconGlutenFree from './images/menu/glutenfree.svg'
import ImgLogo from './images/logo.svg'
import ImgSocialIconFacebook from './images/facebook.svg'
import ImgSocialIconInstagram from './images/instagram.svg'

const menuRaw = (
  <img src={ImgMenuIconRaw} alt={'Raw Fish'} />
);
const menuVeg = (
  <img src={ImgMenuIconVegetarian} alt={'Vegetarian'} />
);
const menuSpicy = (
  <img src={ImgMenuIconSpicy} alt={'Spicy'} />
);
// const menuNew = (
//   <img className="new" src={ImgMenuIconNew} alt={'New'} />
// );
const menuGlutenFree = (
  <img src={ImgMenuIconGlutenFree} alt={'Gluten Friendly'} />
);

const Content = {
  // Pages
  Home: {
    meta: {
      title: 'IZUMI SUSHI BAR + ASIAN BISTRO – Spokane’s South Hill',
      description: 'Izumi Sushi Bar & Asian Bistro provides an exclusive dining experience of fusion style Asian cuisine reminiscent of our great family values.',
      image: {
        src: require('./images/izumi_og.png'),
        alt: 'IZUMI SUSHI BAR + ASIAN BISTRO',
        width: 1200,
        height: 630,
      },
    },
    Carousel: {
      slides: [
        {
          mobileSrc: require('./images/carousel/mobile/c1.jpg'),
          desktopSrc: require('./images/carousel/desktop/c1.jpg'),
          alt: '',
        },{
          mobileSrc: require('./images/carousel/mobile/c2.jpg'),
          desktopSrc: require('./images/carousel/desktop/c2.jpg'),
          alt: '',
        },{
          mobileSrc: require('./images/carousel/mobile/c3.jpg'),
          desktopSrc: require('./images/carousel/desktop/c3.jpg'),
          alt: '',
        },{
          mobileSrc: require('./images/carousel/mobile/c4.jpg'),
          desktopSrc: require('./images/carousel/desktop/c4.jpg'),
          alt: '',
        },{
          mobileSrc: require('./images/carousel/mobile/c5.jpg'),
          desktopSrc: require('./images/carousel/desktop/c5.jpg'),
          alt: '',
        },{
          mobileSrc: require('./images/carousel/mobile/c6.jpg'),
          desktopSrc: require('./images/carousel/desktop/c6.jpg'),
          alt: '',
        },{
          mobileSrc: require('./images/carousel/mobile/c7.jpg'),
          desktopSrc: require('./images/carousel/desktop/c7.jpg'),
          alt: '',
        },{
          mobileSrc: require('./images/carousel/mobile/c8.jpg'),
          desktopSrc: require('./images/carousel/desktop/c8.jpg'),
          alt: '',
        },{
          mobileSrc: require('./images/carousel/mobile/c9.jpg'),
          desktopSrc: require('./images/carousel/desktop/c9.jpg'),
          alt: '',
        },{
          mobileSrc: require('./images/carousel/mobile/c10.jpg'),
          desktopSrc: require('./images/carousel/desktop/c10.jpg'),
          alt: '',
        },{
          mobileSrc: require('./images/carousel/mobile/c11.jpg'),
          desktopSrc: require('./images/carousel/desktop/c11.jpg'),
          alt: '',
        },
      ],
    },
    About: {
      title: 'Izumi Sushi Bar + Asian Bistro',
      subheading: 'Fresh Sushi and made-to-order Asian delights.',
      descriptionHTML: '<p>Izumi Sushi Bar & Asian Bistro provides an exclusive dining experience of fusion style Asian cuisine reminiscent of our great family values. As proud Chinese American owners, we offer everything from traditional sushi rolls to savory wok-fried Chinese entrees using only the highest quality ingredients. If you’re a novice sushi eater or someone with a versatile palate, you are sure to find something that suits your taste.</p>'
    },
    Reviews: {
      images: [
        {
          mobileSrc: require('./images/reviews/mobile/b1.jpg'),
          desktopSrc: require('./images/reviews/desktop/b1.jpg'),
          alt: 'Beautify sushi plate',
        },
        {
          mobileSrc: require('./images/reviews/mobile/b2.jpg'),
          desktopSrc: require('./images/reviews/desktop/b2.jpg'),
          alt: 'Sushi chefs',
        },
        {
          mobileSrc: require('./images/reviews/mobile/b4.jpg'),
          desktopSrc: require('./images/reviews/desktop/b4.jpg'),
          alt: 'Rolls!',
        },
        {
          mobileSrc: require('./images/reviews/mobile/b3.jpg'),
          desktopSrc: require('./images/reviews/desktop/b3.jpg'),
          alt: 'Wontons!',
        },
      ],
      reviews: [
        {
          rating: 5,
          text: "This is my go to place for sushi! LOVE this place! 100% recommend. Great service and AMAZING food! Never disappoints!",
          secondReview: false,
          secondRating: 0,
          secondText: "",
        },
        {
          rating: 5,
          text: "So good! My first ever sushi experience and I’ve been craving it ever since we left! Can’t wait to come back",
          secondReview: false,
          secondRating: 0,
          secondText: "",
        },
        {
          rating: 5,
          text: "Most comfortable, best service, cleanest, imaginative sushi house I've been to in town. Friendly staff, room to breathe. Great food!",
          secondReview: false,
          secondRating: 0,
          secondText: "",
        },
        {
          rating: 5,
          text: "The sushi rolls here do not disappoint! Best in town by far! The service was great even during the busy dinner rush.",
          secondReview: false,
          secondRating: 0,
          secondText: "",
        },
        {
          rating: 5,
          text: "Great Sushi rolls, super friendly staff!",
          secondRating: 5,
          secondReview: true,
          secondText: "OUTSTANDING dining experience! The Las Vegas Roll was amazing!",
        },
      ],
    },
    MobileImage: {
      src: require('./images/reviews/mobile/b3.jpg'),
      alt: 'Wontons!',
    },
    Location: {
      title: 'Location',
      subheading: 'Located on the South Hill in Spokane, WA',
      address: '4334 S. Regal St.',
      cityStateZip: 'Spokane, WA 99223',
      mapImageMobile: require('./images/map-mobile.png'),
      mapImageDesktop: require('./images/map-desktop.png'),
      mapAlt: 'Izumi map',
      mapLink: 'https://goo.gl/maps/TB5Yp95bnzsh44ZeA',
    },
    Hours: {
      title: 'Hours',
      list: [
        'Monday - Friday: 11am to close',
        'Saturday - Sunday: 12pm to close',
      ],
    },
  },
  Menu: {
    meta: {
      title: 'IZUMI SUSHI BAR + ASIAN BISTRO – Spokane’s South Hill | Menu',
      description: 'Izumi Sushi Bar & Asian Bistro provides an exclusive dining experience of fusion style Asian cuisine reminiscent of our great family values.',
      image: null,
    },
    title: "Menu",
    flags: [
      { id: 1, name: 'Raw Fish', icon: ImgMenuIconRaw },
      { id: 2, name: 'Vegetarian', icon: ImgMenuIconVegetarian },
      { id: 3, name: 'Spicy', icon: ImgMenuIconSpicy },
      { id: 4, name: 'Gluten Friendly', icon: ImgMenuIconGlutenFree },
    ],
    sections: [
      {
        id: 'appetizer',
        name: 'Appetizer',
        content: () => { return (
          <div>
            <div className='item'>
              <h3>Oyster Shooter {menuRaw} {menuSpicy} - $6</h3>
            </div>
            <div className='item'>
              <h3>Uni Shooter {menuRaw} {menuSpicy} - $10</h3>
            </div>
            <div className='item'>
              <h3>Edamame {menuVeg} - $7</h3>
            </div>
            <div className='item'>
              <h3>Veggie Spring Roll (5pc) {menuVeg} - $7</h3>
            </div>
            <div className='item'>
              <h3>Pork Dumplings - $8</h3>
              <p>(6pc choice of steam, pan fried, or fried)</p>
            </div>            
            <div className='item'>
              <h3>Crab Cheese Wonton (4pc) - $8</h3>
            </div>
            <div className='item'>
              <h3>Tempura Green Beans {menuVeg} - $10</h3>
            </div>            
            <div className='item'>
              <h3>Lettuce Wrap - $12</h3>
            </div>
            <div className='item'>
              <h3>Fried Calamari - $12</h3>
            </div>
            <div className='item'>
              <h3>Coconut Shrimp with Crushed Walnut - $17</h3>
            </div>            
            <div className='item'>
              <h3>Crazy Jalapeño {menuRaw} {menuSpicy} - $13</h3>
              <p>Spicy tuna, cream cheese, jalapeño deep fried topped with eel sauce, japanese mayo, sriracha, black sesame seed</p>
            </div>
            <div className='item'>
              <h3>Avocado Bomb {menuRaw} {menuSpicy} - $13</h3>
              <p>Half Avacado, spicy tuna, crabmeat, deep fried, drizzled eel sauce, spicy mayo & tempura crumbs</p>
            </div>
            <div className='item'>
              <h3>Yellowtail & Jalapeño salsa {menuGlutenFree} {menuRaw} {menuSpicy} - $12</h3>
              <p>5 pieces of yellowtail, cilantro, salsa, yuzu sauce</p>
            </div>
            <div className='item'>
              <h3>Sashimi Appetizer {menuGlutenFree} {menuRaw} - $16</h3>
              <p>2 pieces of tuna & yellowtail, 3 pieces of salmon</p>
            </div>
            <div className='item'>
              <h3>Shrimp and Veggie Tempura - $15</h3> 
              <p>4 pieces of shrimp with variety of veggies</p>
            </div>
            <div className='item'>
              <h3>Fresh Oyster {menuGlutenFree} {menuSpicy} - $21</h3>
              <p>Half dozen of fresh oyster, ponzu sauce, lemon juice, sriracha, scallions, black tobiko</p>
            </div>
            <div className='item'>
              <h3>Hamachi Kama {menuGlutenFree} - $12</h3>
              <p>Baked yellowtail collar with ponzu sauce</p>
            </div>
            <div className='item'>
              <h3>Modern Style Salmon {menuGlutenFree} {menuRaw} {menuSpicy} - $15</h3> 
              <p>6 piece seared salmon belly, fresh wasabi, scallions and ponzu</p>
            </div>
            <div className='item'>
              <h3>Tuna Tataki {menuGlutenFree} {menuRaw} - $15 (Traditional)</h3> 
              <p>6 pieces of seared tuna, onion, scallion, black sesame seed, ponzu sauce</p>
            </div>
            <div className='item'>
              <h3>Beef Tataki {menuGlutenFree} {menuRaw} {menuSpicy} - $20</h3> 
              <p>6 piece seared A5 wagyu beef with ponzu sauce, garlic butter, siracha, scallion</p>
            </div>
            <div className='item'>
              <h3>Never Board Tuna {menuGlutenFree} {menuRaw} {menuSpicy} - $21</h3>
              <p>9 piece of chili pepper tuna, cucumber, never board sauce, masago, scallion, black sesame seed</p>
            </div>
          </div>
        )},
      },
      {
        id: 'soup-and-salad',
        name: 'Soup & Salad',
        content: () => { return (
          <div>
            <div className='item'>
              <h3>Seaweed Salad {menuVeg} - $8</h3>
            </div>
            <div className='item'>
              <h3>Cucumber Salad {menuGlutenFree} {menuVeg} - $8</h3>
            </div>
            <div className='item'>
              <h3>Salmon Skin Salad - $10</h3>
              <p>Smoked salmon skin, seaweed salad, cucumber, masago, sesame seed, scallions, ponzu sauce</p>
            </div>
            <div className='item'>
              <h3>Soft Shell Crab Salad {menuSpicy} - $12</h3>
              <p>Soft shell crab, spring mix, masago, sesame seed, spicy mayo</p>
            </div>
            <div className='item'>
              <h3>Poki Salad {menuGlutenFree} {menuRaw} {menuSpicy} - $18</h3>
              <p>Cucumber, avocado, tuna, escolar, salmon, yellowtail, sesame seed, ponzu sauce and garlic dressing</p>
            </div>
            <div className='item'>
              <h3>Miso (Cup or Bowl) {menuVeg} - $3.5/$7</h3>
            </div>
            <div className='item'>
              <h3>Egg Flower (Cup or Bowl) - $3.5/$8</h3>
            </div>
            <div className='item'>
              <h3>Wonton Soup (Bowl) - $12</h3>
            </div>
            <div className='item'>
              <h3>Hot Sour (Bowl) {menuSpicy} - $9</h3>
            </div>
          </div>
        )},
      },
      {
        id: 'nigiri-and-sashimi',
        name: 'Nigiri & Sashimi',
        subheading: 'Nigiri (2pcs) & Sashimi (3pcs)',
        content: () => { return (
          <div>
            <div className='item'>
              <h3>Bluefin Tuna (Hon Maguro) {menuRaw} - $9/10</h3>
            </div>
            <div className='item'>
              <h3>Medium Fatty Tuna (Chu-Toro) {menuRaw} - $12/22 (4 pc.)</h3>
            </div>
            <div className='item'>
              <h3>Fatty Tuna (O-Toro) {menuRaw} - $15/28 (4pc.)</h3>
            </div>
            <div className='item'>
              <h3>Salmon (Sake) {menuRaw} - $7/8</h3>
            </div>
            <div className='item'>
              <h3>Yellowtail (Hamachi) {menuRaw} - $7/8</h3>
            </div>
            <div className='item'>
              <h3>Amberjack (Kanpachi) {menuRaw} - $8/9</h3>
            </div>
            <div className='item'>
              <h3>Striped Bass {menuRaw} - $8/9</h3>
            </div>
            <div className='item'>
              <h3>Sea Bream (Madai) {menuRaw} - $8/9</h3>
            </div>
            <div className='item'>
              <h3>Super White Tuna (Escolar) {menuRaw} - $7/8</h3>
            </div>
            <div className='item'>
              <h3>Albacore (Shiro Maguro) {menuRaw} - $7/8</h3>
            </div>
            <div className='item'>
              <h3>Eel (Unagi) - $7/8</h3>
            </div>
            <div className='item'>
              <h3>Egg Omelet (Tamago) - $6/7</h3>
            </div>
            <div className='item'>
              <h3>Flying Fish Row (Tobiko) {menuRaw} - $7/8</h3>
            </div>
            <div className='item'>
              <h3>Smelt Row (Masago) {menuRaw} - $7/8</h3>
            </div>
            <div className='item'>
              <h3>Salmon Row (Ikura) {menuRaw} - $9/10</h3>
            </div>
            <div className='item'>
              <h3>Mackerel (Saba) {menuRaw} - $6/7</h3>
            </div>
            <div className='item'>
              <h3>Octopus (Tako) - $7/8</h3>
            </div>
            <div className='item'>
              <h3>Scallop (Hotate) {menuRaw} - $7/8</h3>
            </div>
            <div className='item'>
              <h3>Scallop Chopped (Gonkan) {menuRaw} - $7</h3>
            </div>
            <div className='item'>
              <h3>Shrimp (Ebi) - $7/8</h3>
            </div>
            <div className='item'>
              <h3>Smoked Salmon {menuRaw} - $7/8</h3>
            </div>
            <div className='item'>
              <h3>Surf Clam (Hokigai) {menuRaw} - $6/7</h3>
            </div>
            <div className='item'>
              <h3>Sweet Shrimp (Ama ebi) {menuRaw} - $10 (Nigiri Only)</h3>
            </div>
            <div className='item'>
              <h3>Snowcrab - $9/12</h3>
            </div>
            <div className='item'>
              <h3>Alaska King Crab (2 pc.) - $15</h3>
            </div>
            <div className='item'>
              <h3>Sea Urchin (Uni) {menuRaw} - M/P</h3>
            </div>
            <div className='item'>
              <h3>A5 Wagyu Beef (2 pc.) {menuRaw} - $15</h3>
            </div>
          </div>
        )},
      },
      {
        id: 'rolls',
        name: 'Rolls',
        content: () => { return (
          <div>
            <div className='item'>
              <h3>Avocado Roll {menuGlutenFree} {menuVeg} - $6</h3>
            </div>            
            <div className='item'>
              <h3>Cucumber Roll (kappa maki) {menuGlutenFree} {menuVeg} - $6</h3>
            </div>
            <div className='item'>
              <h3>Asparagus Roll {menuGlutenFree} {menuVeg} - $6</h3>
            </div>
            <div className='item'>
              <h3>Salmon Roll (sake maki) {menuGlutenFree} {menuRaw} - $8</h3>
            </div>
            <div className='item'>
              <h3>Tuna Roll (tekka maki) {menuGlutenFree} {menuRaw} - $9</h3>
            </div>
            <div className='item'>
              <h3>Yellowtail & Scallion Roll (Hamachi maki) {menuGlutenFree} {menuRaw} - $9</h3>
            </div>
            <div className='item'>
              <h3>California Roll - $8</h3>
              <p>Crabstick, avocado, cucumber inside, masago on top</p>
            </div>
            <div className='item'>
              <h3>Spicy California Roll - $8</h3>
              <p>Spicy crabmeat, avocado, cucumber inside, topped with masago</p>
            </div>
            <div className='item'>
              <h3>Cucumber Avocado Roll {menuGlutenFree} {menuVeg} - $7</h3>
            </div>            
            <div className='item'>
              <h3>Vegetable Roll {menuGlutenFree} {menuVeg} - $8</h3>
              <p>Cucumber, avocado, asparagus, yamagobo, oshiko, lettuce</p>
            </div>
            <div className='item'>
              <h3>Crawfish Roll {menuSpicy} - $10</h3>
              <p>Crawfish and avocado</p>
            </div>
            <div className='item'>
              <h3>Spicy Salmon Roll {menuRaw} {menuSpicy} - $8</h3>
              <p>Spicy salmon and cucumber</p>
            </div>
            <div className='item'>
              <h3>Spicy Tuna Roll {menuRaw} {menuSpicy} - $9</h3>
              <p>Spicy tuna and cucumber</p>
            </div>
            <div className='item'>
              <h3>Spicy Yellowtail Roll {menuRaw} {menuSpicy} - $9</h3>
              <p>Spicy yellowtail, cucumber, scallions</p>
            </div>
            <div className='item'>
              <h3>Eel Avocado Roll - $10</h3>
            </div>            
            <div className='item'>
              <h3>Philadelphia Roll {menuGlutenFree} - $11</h3>
              <p>Smoked salmon, cream cheese, avocado</p>
            </div>
            <div className='item'>
              <h3>Crunchy Roll - $8</h3>
              <p>Crab meat, avocado, crunchy</p>
            </div>
            <div className='item'>
              <h3>Rock-N-Roll - $12</h3>
              <p>Crab meat, avocado, 2 pieces of shrimp tempura inside, masago on top with eel sauce</p>
            </div>
            <div className='item'>
              <h3>Shrimp Tempura Roll - $12</h3>
              <p>Cucumber, lettuce, 2 pieces of shrimp tempura inside, masago on top with eel sauce</p>
            </div>
            <div className='item'>
              <h3>Spider Roll - $12</h3>
              <p>Tempura Soft shell crab, cucumber, lettuce inside, masago on top with eel sauce</p>
            </div>
            <div className='item'>
              <h3>
                <i>Substitute Rice Paper - $3 or Soy Paper - $1 extra</i>
              </h3>
            </div>
            <div className='item'>
              <h3>
                <i>Substitute Snowcrab - $9 or Alaska King Crab - $15</i>
              </h3>
            </div>
          </div>
        )},
      },
      {
        id: 'special-roll',
        name: 'Special Roll',
        content: () => { return (
          <div>
            <div className='item'>
              <h3>Lobster Tempura {menuSpicy} - $20</h3>
              <p>Lobstertail tempura wrapped with soy paper, spicy crabmeat, avocado drizzled with eel sauce and spicy mayo</p>
            </div>
            <div className='item'>
              <h3>Rainbow Roll {menuRaw} - $15 (Try with spicy crabmeat)</h3>
              <p>Crab meat, cucumber, inside, tuna, salmon, yellowtail, escolar, avocado on top</p>
            </div>
            <div className='item'>
              <h3>Las Vegas Roll {menuRaw} {menuSpicy} - $15</h3>
              <p>Shrimp tempura and asparagus tempura inside, spicy tuna on top with crunchy, eel sauce, spicy mayo, masago, scallions</p>
            </div>
            <div className='item'>
              <h3>Bruce Lee {menuRaw} {menuSpicy} - $15</h3>
              <p>Spicy crabmeat, cucumber inside with tuna avocado on top, sriracha spicy mayo, chilli oil, scallions, masago</p>
            </div>
            <div className='item'>
              <h3>Fresh Roll {menuGlutenFree} {menuRaw} - $17 </h3>
              <p>Salmon with red tobiko inside, sliced salmon on top with fresh lemon slices (Substitue with tuna or yellow tail - $2 extra)</p>
            </div>
            <div className='item'>
              <h3>Izumi Roll - $15</h3>
              <p>Shrimp tempura, crab meat, avocado, asparagus, masago wrapped with soybean paper, eel sauce</p>
            </div>
            <div className='item'>
              <h3>Tiger Roll {menuRaw} - $17</h3>
              <p>Tuna, salmon inside, tuna, salmon, BBQ eel, avocado on top with eel sauce, scallions, masago</p>
            </div>
            <div className='item'>
              <h3>New Orleans Roll {menuRaw} {menuSpicy} - $16</h3>
              <p>Crawfish, avocado, crunchy inside, spicy tuna, crab meat on top with eel sauce, wasabi tobiko</p>
            </div>
            <div className='item'>
              <h3>Downtown Roll {menuRaw} {menuSpicy} - $15</h3>
              <p>Chopped scallop, masago, scallion, chili oil, crunchy inside, chopped tuna, salmon, yellowtail on top with crunchy, scallions, red tobiko</p>
            </div>
            <div className='item'>
              <h3>Seahawks Roll {menuRaw} {menuSpicy} - $17</h3>
              <p>Spicy tuna, crunchy, avocado inside, BBQ eel on top with eel sauce, wasabi tobiko</p>
            </div>
            <div className='item'>
              <h3>Lobster Roll {menuSpicy} - $35</h3>
              <p>Crabmeat, avocado inside, whole 7-8oz lobster tail meat baked on top, drizzled with eel sauce, spicy mayo, red tobiko</p>
            </div>
            <div className='item'>
              <h3>Rising Sun Roll {menuSpicy} - $15</h3>
              <p>California roll inside, salmon on top with spicy mayo, baked with crunchy, rice noodle, eel sauce</p>
            </div>
            <div className='item'>
              <h3>Spider Web  {menuRaw} {menuSpicy} - $16</h3>
              <p>Tempura Soft shell crab, cucumber, avocado wrapped with big seaweed, wasabi tobiko, spicy tuna, crunchy on top, eel sauce, spicy mayo</p>
            </div>
            <div className='item'>
              <h3>Hard Rock Roll {menuSpicy} - $18</h3>
              <p>Seaweed powder, masago, mayonnaise, shrimp tempura, BBQ eel, avocado, crab stick wrapped with rice paper, eel sauce, spicy mayo, hoszin</p>
            </div>
            <div className='item'>
              <h3>Rice Paper Roll {menuRaw} {menuSpicy} - $18</h3>
              <p>Salmon, tuna, masago, cucumber, crab stick, avocado, lettuce, mango wrap with rice paper, eel sauce, wasabi mayo, sriracha</p>
            </div>
            <div className='item'>
              <h3>Utah Roll {menuRaw} {menuSpicy} - $15</h3>
              <p>Salmon, avocado, crab meat, cream cheese inside, deep fried topped with eel sauce and spicy mayo</p>
            </div>
            <div className='item'>
              <h3>Cowboy {menuRaw} {menuSpicy} - $25</h3>
              <p>Crab meat, shrimp tempura rolled in the middle topped with seared A5 wagyu beef, eel sauce, ponzu, chili powder, scallion, black tabiko</p>
            </div>
            <div className='item'>
              <h3>Godzilla - $17</h3>
              <p>Shrimp tempura, crab meat, cucumber, eel, avocado on top with drizzled eel sauce, masago and scallion</p>
            </div>
            <div className='item'>
              <h3>Hoppy Roll {menuRaw} - $17</h3>
              <p>Crab meat, cucumber and shrimp tempura inside, tuna, avocado on top with eel sauce, masago scallion</p>
            </div>
            <div className='item'>
              <h3>Flying Dragon {menuGlutenFree} {menuRaw} {menuSpicy} - $15</h3>
              <p>Tuna, cilantro, jalapeño, red bell pepper, mango, chili oil, lemon juice wrapped with big seaweed</p>
            </div>
            <div className='item'>
              <h3>Fire Roll {menuRaw} {menuSpicy} - $15</h3>
              <p>Spicy salmon, cream cheese, jalapeño, crunchy inside, crab stick on top with eel sauce, spicy mayo</p>
            </div>
            <div className='item'>
              <h3>Tuna and Tuna Roll {menuRaw} {menuSpicy} - $16</h3>
              <p>Spicy tuna, crunchy, cucumber inside, chili pepper tuna, avocado on top with Izumi garlic sauce</p>
            </div>
            <div className='item'>
              <h3>Banana Roll {menuRaw} - $15</h3>
              <p>Salmon, cream cheese, banana, masago, avocado wrapped with soy paper deep fried, drizzled with eel sauce on top</p>
            </div>
            <div className='item'>
              <h3>Lava Roll {menuSpicy} - $16</h3>
              <p>2 pieces of shrimp tempura, smoked salmon, cream cheese, jalapeño wrapped with big seaweed, deep fried with eel sauce and spicy mayo</p>
            </div>
            <div className='item'>
              <h3>Dragon Roll - $15</h3>
              <p>Crab meat, cucumber inside, BBQ eel, avocado on top with eel sauce</p>
            </div>
            <div className='item'>
              <h3>Volcano - $15</h3>
              <p>Shrimp tempura, avocado, cream cheese inside, topped with baked crabmeat scallops, drizzled with eel sauce</p>
            </div>
            <div className='item'>
              <h3>Caterpillar - $15</h3>
              <p>BBQ eel, cucumber inside, avocado on top with eel sauce</p>
            </div>
            <div className='item'>
              <h3>Kamikaze {menuSpicy} - $15</h3>
              <p>Spicy crab meat, avocado, crunchy, topped with smoked salmon, yumyum sauce torched, drizzled with eel sauce</p>
            </div>
            <div className='item'>
              <h3>Mango Roll - $15</h3>
              <p>2 pieces of shrimp tempura, crab meat, cucumber inside, mango, avocado on top with eel sauce</p>
            </div>
            <div className='item'>
              <h3>Washington Roll {menuRaw} {menuSpicy} - $15</h3>
              <p>Yellowtail, scallion, asparagus, jalapeño wasabi mayo inside, spicy salmon and crunchy on top with black tabiko</p>
            </div>
            <div className='item'>
              <h3>Flaming Valcano {menuRaw} {menuSpicy} - $15</h3>
              <p>Crab stick, tamago, shrimp, eel, deep fried and topped with spicy tuna, crabmeat, eel sauce and spicy mayo</p>
            </div>
            <div className='item'>
              <h3>Rainbow Naruto {menuRaw} {menuSpicy} - $18</h3>
              <p>Tuna, salmon, yellowtail, white fish, masago, crab meat, avocado wrapped with cucumber, wasabi mayo sauce</p>
            </div>
            <div className='item'>
              <h3>Po-boy - $17</h3>
              <p>Crawfish, avocado, shrimp tempura wraped with soy paper, drizzled with eel sauce</p>
            </div>
            <div className='item'>
              <h3>Royal King {menuGlutenFree} {menuRaw} - $30</h3>
              <p>Salmon, avocado, mango inside, Alaska King Crab on top, baked with mayo, topped with red tabiko, crunchy sweet potato.</p>
            </div>
            <div className='item'>
              <h3>
                <i>Add a pair of Alaska King Crab nigiri - $12 extra</i>
              </h3>
            </div>            
          </div>
        )},
      },
      {
        id: 'sushi-combo',
        name: 'Sushi Combo',
        content: () => { return (
          <div>
            <div className='item'>
              <h3>Sushi Regular {menuGlutenFree} {menuRaw} - $30</h3>
              <p>Chef’s selection 8pcs of sushi with tuna roll</p>
            </div>
            <div className='item'>
              <h3>Sushi & Sashimi Combo {menuRaw} - $40</h3>
              <p>Chef’s selection 12 pcs of sashimi and 5pcs of sushi with california roll</p>
            </div>
            <div className='item'>
              <h3>Sashimi Deluxe {menuGlutenFree} {menuRaw} - $44</h3>
              <p>Chef’s selection 18 pcs of sashimi</p>
            </div>
            <div className='item'>
              <h3>Chirashi {menuRaw} - $40</h3>
              <p>Chef’s selection 15 pcs of sashimi on a bed of sushi rice</p>
            </div>
            <div className='item'>
              <h3>Lover’s Boat {menuRaw} - $72</h3>
              <p>Chef’s selection 12pcs of sashimi,8 pcs of sushi, rainbow roll and spider roll</p>
            </div>
            <div className='item'>
              <h3>Izumi Super Boat {menuRaw} - $99</h3>
              <p>Chef’s selection 18pcs of sashimi, 12pcs of sushi, California roll, rainbow roll, spider roll and rock-n-roll</p>
            </div>
          </div>
        )},
      },
      {
        id: 'dinner-entree',
        name: 'Dinner Entrée',
        subheading: 'Substitute egg fried rice - $6',
        content: () => { return (
          <div>
            <div className='item'>
              <h3>Kung Pao Chicken {menuSpicy} - $15</h3>
              <h3>Substitute w/ Beef {menuSpicy} - $17</h3>
              <h3>Substitute w/ Shrimp/Fish {menuSpicy} - $17</h3>
              <p>Traditional house spicy kungpao sauce stir-fried with bell peppers, onion, water chestnuts and topped with peanuts</p>
            </div>
            <div className='item'>
              <h3>Cashewnut Chicken - $15</h3>
              <h3>Substitute With Shrimp/Fish - $17</h3>
              <p>Celery, onion, carrot, bell pepper in oyster ginger sauce</p>
            </div>
            <div className='item'>
              <h3>House Specialty Yuxiang Chicken {menuSpicy} - $15</h3>
              <h3>Substitute with Beef {menuSpicy} - $17</h3>
              <p>Thin sliced meat with sliced bell pepper, onion, bamboo shoots, Asian mushroom in a spicy ginger flavored sauce</p>
            </div>
            <div className='item'>
              <h3>Sweet & Sour Chicken - $16</h3>
              <h3>Substitute with Fish - $17</h3>
              <p>Breast meat with pineapple, bell pepper and onions</p>
            </div>
            <div className='item'>
              <h3>General Tso Chicken {menuSpicy} - $16</h3>
              <p>Fired chicken glazed with dark brown sweet spicy sauce</p>
            </div>
            <div className='item'>
              <h3>Tangerine Beef {menuSpicy} - $18</h3>
              <h3>Substitute with Chicken {menuSpicy} - $16</h3>
              <p>Crispy beef with orange flavored sauce</p>
            </div>
            <div className='item'>
              <h3>Black Pepper Beef - $18</h3>
              <p>Beef wok fried with bell pepper and onion in black pepper sauce</p>
            </div>
            <div className='item'>
              <h3>Mongolian Beef {menuSpicy} - $18</h3>
              <p>Tenderloin beef with green onion and onion</p>
            </div>
            <div className='item'>
              <h3>Beef with Broccoli - $17</h3>
              <p>Sliced beef with a ring of steamed broccoli, carrots, onions and mushrooms</p>
            </div>
            <div className='item'>
              <h3>Salt & Pepper Chicken {menuSpicy} - $16</h3>
            </div>
            <div className='item'>
              <h3>Salt & Pepper Fish {menuSpicy} - $18</h3>
            </div>
            <div className='item'>
              <h3>Teriyaki Chicken - $17</h3>
              <p>Grilled chicken breast with sweet teriyaki sauce served with a house salad</p>
            </div>
            <div className='item'>
              <h3>Teriyaki Beef - $22</h3>
              <p>Grilled beef steak with sweet teriyaki sauce served with a house salad</p>
            </div>
            <div className='item'>
              <h3>
                <i>Tofu may be substituted.</i>
              </h3>
              <h3>
                <i>Extra protien uppon request</i>
              </h3>
              <h3>
                <i>Chicken, BBQ pork veggies - $5,</i>
              </h3>
              <h3>
                <i>Extra beef - $7 or extra shrimp (5pcs) - $8</i>
              </h3>
            </div>
          </div>
        )},
      },
      {
        id: 'noodle-rice',
        name: 'Noodle / Rice',
        content: () => { return (
          <div>
            <div className='item'>
              <h3>Chow Mein - $16</h3>
              <p>Choice of Chicken, BBQ Pork or Veggie {menuVeg}</p>
            </div>
            <div className='item'>
              <h3>Beef or Shrimp Chow Mein - $18</h3>
            </div>
            <div className='item'>
              <h3>Combo Chow Mein</h3>
              <h3>(Chicken, Beef and Shrimp) - $20</h3>
            </div>
            <div className='item'>
              <h3>Fried Rice {menuGlutenFree} - $16</h3>
              <p>Choice of Chicken, BBQ Pork or Veggie {menuVeg}</p>
            </div>
            <div className='item'>
              <h3>Beef or Shrimp Fried Rice {menuGlutenFree} - $18</h3>
            </div>
            <div className='item'>
              <h3>Combo Fried Rice {menuGlutenFree} - $20</h3>
              <p>(Chicken, Beef and Shrimp)</p>
            </div>
          </div>
        )},
      },
      {
        id: 'dinner-bento-box',
        name: 'Dinner Bento Box',
        content: () => { return (
          <div>
            <div className='item'>
              <p>(Comes with daily soup dine in only, shrimp and vegetable tempura, pan seared dumplings, white rice, salad and 4pcs California Roll)</p>
            </div>
            <div className='item'>
              <h3>Teriyaki Chicken Bento - $22</h3>
            </div>
            <div className='item'>
              <h3>Teriyaki Beef Bento - $24</h3>
            </div>
            <div className='item'>
              <h3>Salmon Bento - $27</h3>
            </div>
            <div className='item'>
              <h3>Sushi and Sashimi Bento - $27</h3>
            </div>
          </div>
        )},
      },
      {
        id: 'lunch-bento-box',
        name: 'Lunch Bento Box',
        subheading: 'Served Mon - Fri 11am - 3pm',
        content: () => { return (
          <div>
            <div className='item'>
              <p>(Comes with daily soup dine in only, white rice, 4piece California Roll)</p>
            </div>
            <div className='item'>
              <h3>Teriyaki Chicken Bento - $15</h3>
            </div>
            <div className='item'>
              <h3>Teriyaki Beef Bento - $16</h3>
            </div>
            <div className='item'>
              <h3>Tempura Bento - $16</h3>
            </div>
          </div>
        )},
      },
      {
        id: 'lunch-sushi-special',
        name: 'Lunch Sushi Special',
        subheading: 'Served Mon - Fri 11am - 3pm',
        content: () => { return (
          <div>
            <div className='item'>
              <p>(Comes with daily soup dine in only, excluding major holidays)</p>
            </div>
            <div className='item'>
              <h3>Combo A - $14 (Two of the following rolls)</h3>
            </div>
            <div className='item'>
              <h3>Combo B - $19 (Three of the following rolls)</h3>
              <p></p>
            </div>
            <div className='item'>
              <p>
                <i>Substitutions are politely rejected</i>
              </p>
            </div>
            <div className='item cols2'>
              <div>Avocado Roll</div> <div>Crawfish Roll {menuSpicy}</div>
              <div>Cucumber Roll</div> <div>Spicy Salmon Roll {menuRaw} {menuSpicy}</div>
              <div>Aspargus Roll</div> <div>Spicy Tuna Roll {menuRaw} {menuSpicy}</div>
              <div>Salmon Roll {menuRaw}</div> <div>Spicy Yellowtail Roll {menuRaw} {menuSpicy}</div>
              <div>Tuna Roll {menuRaw}</div> <div>Spicy California {menuSpicy}</div>
              <div>Yellowtail & Scallion Roll {menuRaw}</div> <div>Philadelphia Roll {menuRaw}</div>
              <div>California Roll</div> <div>Crunchy Roll</div>
              <div>Cucumber Avocado Roll</div> <div>Eel Avocado Roll</div>
              <div>Vegetable Roll</div> 
            </div>
          </div>
        )},
      },
      {
        id: 'lunch-special',
        name: 'Lunch Special',
        subheading: 'Served Mon - Fri 11am - 3pm',
        content: () => { return (
          <div>
            <div className='item'>
              <p>(Comes with daily soup dine in only, white rice and house salad)</p>
            </div>
            <div className='item'>
              <h3>Kungpao Chicken or Beef {menuSpicy} - $14</h3>
              <p>(Substitute with shrimp - extra $2)</p>
            </div>
            <div className='item'>
              <h3>Cashewnut Chicken - $14</h3>
            </div>
            <div className='item'>
              <h3>Sweet & Sour Chicken - $14</h3>
            </div>
            <div className='item'>
              <h3>Yuxiang Chicken or Pork {menuSpicy} - $14</h3>
              <p>(Substitute with shrimp - extra $2)</p>
            </div>
            <div className='item'>
              <h3>General Tso's Chicken {menuSpicy} - $14</h3>
            </div>
            <div className='item'>
              <h3>Orange Chicken {menuSpicy} - $14</h3>
              <p></p>
            </div>
            <div className='item'>
              <h3>Mongolian Beef {menuSpicy} - $14</h3>
              <p></p>
            </div>
            <div className='item'>
              <h3>Beef with Broccoli - $14</h3>
              <p></p>
            </div>
          </div>
        )},
      },
      {
        id: 'dessert',
        name: 'Dessert',
        content: () => { return (
          <div>
            <div className='item'>
              <h3>Mochi Balls (2pc) - $5</h3>
              <p>(Green tea, mango, red bean, vanilla, strawberry and chocolate)</p>
            </div>
          </div>
        )},
      },
    ]
  },

  // Global
  Logo: {
    src: ImgLogo,
    alt: 'Izumi - Sushi Bar + Asian Bistro',
  },
  Reservations: {
    label: 'Reservations',
    phone: '509-443-3865',
    link: 'tel:+15094433865',
  },
  Social: {
    facebook: {
      icon: ImgSocialIconFacebook,
      width: 16,
      height: 30,
      alt: 'Izumi Facebook',
      link: 'https://www.facebook.com/izumisushispokane/',
    },
    instagram: {
      icon: ImgSocialIconInstagram,
      width: 28,
      height: 28,
      alt: 'Izumi Instagram',
      link: 'https://www.instagram.com/izumispokane/',
    },
  },
  Footer: {
    copyrightBy: 'Izumi Sushi & Bistro',
    author: 'Website by Codespeed',
    authorHref: 'https://www.codespeed.com',
  },

  // Notices
  Notices: [
  ],
};
export default Content;
