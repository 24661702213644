const DEFAULT_STATE = {
  breakpoint: 'unknown',
  windowWidth: 0,
  windowHeight: 0,
  windowPageYOffset: 0,
};

export default function AppReducer(state = DEFAULT_STATE, action) {
  switch(action.type) {
    case 'SET_WINDOW_DIMENSIONS':
      //console.log('[AppCoreReducer] Action:', action);
      return Object.assign({}, state, {
        breakpoint: action.breakpoint,
        windowWidth: action.windowWidth,
        windowHeight: action.windowHeight,
      });

    case 'SET_WINDOW_SCROLL_OFFSET':
      //console.log('[AppCoreReducer] Action:', action);
      return Object.assign({}, state, {
        windowPageYOffset: action.windowPageYOffset,
      });

    default:
      return state;
  }
}
