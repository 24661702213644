const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

export default class Breakpoints {
  static getBreakpoint() {
    let w = window.innerWidth;

    if (w >= BREAKPOINTS.xl) return 'xl';
    if (w >= BREAKPOINTS.lg) return 'lg';
    if (w >= BREAKPOINTS.md) return 'md';
    if (w >= BREAKPOINTS.sm) return 'sm';
    return 'xs';
  }
  static isMobile(bp) {
    return (bp === 'xs' || bp === 'sm');
  }
  static isTablet(bp) {
    return (bp === 'md');
  }
  static isDesktop(bp) {
    return (bp === 'lg' || bp === 'xl');
  }
}
