import React, { Component } from 'react';
import AppReference from '../utils/AppReference';

import './Home.scss';
import Content from '../Content';
import MetaTags from '../components/MetaTags';
import HomeHeader from '../components/HomeHeader';
import ButtonReservationBar from '../components/ButtonReservationBar';
import ImageCarousel from '../components/ImageCarousel';
import HomeAbout from '../components/HomeAbout';
import HomeReviews from '../components/HomeReviews';
import HomeMenu from '../components/HomeMenu';
import HomeLocationsAndHours from '../components/HomeLocationsAndHours';
import ButtonSocialBar from '../components/ButtonSocialBar';
import Footer from '../components/Footer';
import MobileImage from '../components/MobileImage';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // Variables
    this.app = AppReference.get();

    // this binding
  }
  componentDidMount() {
    let anchors = ['about','reviews','location-and-hours'];
    this.app.setHashUpdateOnScrollAnchors(anchors);
  }
  render() {
    const { Home } = Content;

    return (
      <div className='Home'>
        <MetaTags meta={Home.meta} />
        <HomeHeader />
        <ButtonReservationBar />
        <ImageCarousel slides={Home.Carousel.slides} />
        <HomeAbout id='about' />
        <HomeReviews id='reviews' />
        <HomeMenu />
        <MobileImage image={Home.MobileImage} />
        <ButtonReservationBar />
        <HomeLocationsAndHours id='location-and-hours' />
        <ButtonSocialBar />
        <Footer />
      </div>
    );
  }
}
