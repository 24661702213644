import React from 'react';
import './Button.scss';

Button.defaultProps = {
  href: '#',
  target: '_self',
  children: [],
  onClick: null,
};

export default function Button(props) {
  const {
    href,
    target,
    children,
    onClick,
  } = props;

  return (
    <a className='Button' href={href} target={target} onClick={onClick}>
      {children}
    </a>
  );
}
