import React, { Component } from 'react';
import './Preloader.scss';

export default class Preloader extends Component {
  static defaultProps = {
    preloading: false,
  }

  render() {
    const { preloading } = this.props;

    return (
      <div className={'Preloader '+(preloading ? 'show' : '')}>
        <div className='spinner'>
          <div className='bounce1'></div>
          <div className='bounce2'></div>
          <div className='bounce3'></div>
        </div>
      </div>
    );
  }
}
