import React, { Component } from 'react';

import './NavHamburger.scss';

export default class NavHamburger extends Component {
  static defaultProps = {
    isActive: false,
    onClick: null,
  }

  render() {
    const { isActive, onClick } = this.props;

    return (
      <button className={"NavHamburger hamburger hamburger--spring "+(isActive ? 'is-active': '')} type="button" onClick={onClick}>
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    );
  }
}
