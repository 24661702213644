import { combineReducers, createStore } from 'redux';
import AppReducer from './reducers/AppReducer';

const rootReducer = combineReducers({
  AppReducer,
});

const store = createStore(rootReducer);

export { store };
