import React, { Component } from 'react';

import './ButtonSocialBar.scss';
import Content from '../Content';
import ButtonSocial from './ButtonSocial';

export default class ButtonSocialBar extends Component {
  static defaultProps = {
  }

  render() {
    const { Social } = Content;

    let buttons = [];
    for (const s in Social) {
      buttons.push(
        <ButtonSocial
          key={"social-"+s}
          icon={Social[s].icon}
          iconWidth={Social[s].width}
          iconHeight={Social[s].height}
          alt={Social[s].alt}
          link={Social[s].link}
          onClick={(e) => {

            window.gtag('event', 'Click', {
              'event_category' : 'Social',
              'event_label' : Social[s].alt,
            });
          }}
        />
      );
    }

    return (
      <div className='ButtonSocialBar'>
        {buttons}
      </div>
    );
  }
}
