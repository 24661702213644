import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import Button from '../Button';
import Content from '../../Content';
import './ModalNotice.scss';

ModalNotice.defaultProps = {
};

export default function ModalNotice(props) {
  //const { } = props;
  const Notices = Content.Notices;
  const [isOpen, setIsOpen] = useState(false);
  const [heading, setHeading] = useState('');
  const [body, setBody] = useState('');

  function shouldShowNotice(notice) {
    const seenAt = parseInt(localStorage.getItem(notice.storageKey));

    if (!isNaN(seenAt)) {
      const between = Date.now() - seenAt;
      if (between > 60 * 60 * 1000) { // 60 minutes
        localStorage.setItem(notice.storageKey, Date.now());
        return true;
      }
      return false;
    }
    else {
      localStorage.setItem(notice.storageKey, Date.now());
      return true;
    }
  }

  function showNotice(notice) {
    setHeading(notice.heading);
    setBody(notice.body);
    setIsOpen(true);
  }
  function onClose(e) {
    if (e) e.preventDefault();
    setIsOpen(false);
  }

  useEffect(() => {
    let now = new Date();

    for (let i = 0; i < Notices.length; i++) {
      let notice = Notices[i];

      if (notice.startsAt < now && now < notice.endsAt) {
        if (shouldShowNotice(notice)) {
          setTimeout(() => {
            showNotice(notice);
          }, 300);
        }
        break;
      }
    }
  }, [Notices]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="ModalNotice">
        <div className="heading">{heading}</div>
        <div className="body" dangerouslySetInnerHTML={{ __html: body }}></div>
        <div className="buttons">
          <Button onClick={onClose}>
            Close
          </Button>
        </div>
      </div>
    </Modal>
  );
}
