import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import './Header.scss';
import Content from '../Content';
import NavLink from './NavLink';

const COLLAPSE_Y = 237;

class Header extends Component {
  static defaultProps = {}

  // Lifecycle
  constructor(props) {
    super(props);
    this.state = {};

    // this binding
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location !== nextProps.location)
      return true;

    if (this.props.windowPageYOffset !== nextProps.windowPageYOffset) {
      if (this.props.windowPageYOffset >= COLLAPSE_Y && nextProps.windowPageYOffset < COLLAPSE_Y)
        return true;
      if (this.props.windowPageYOffset < COLLAPSE_Y && nextProps.windowPageYOffset >= COLLAPSE_Y)
        return true;
    }

    return false;
  }
  render() {
    const { Logo } = Content;
    const { windowPageYOffset, location } = this.props;
    const collapsed = (windowPageYOffset >= COLLAPSE_Y);

    let forceCollapse = false;
    let title = '';
    if (location.pathname === '/menu') {
      title = 'Menu';
      forceCollapse = true;
    }

    return (
      <div className={'Header'}>
        <div className={'bar' + (collapsed || forceCollapse ? ' active' : '')}>
          <NavLink className='bar-logo' to='/'>
            <img src={Logo.src} alt={Logo.alt} />
          </NavLink>
          <div className='title'>{title}</div>
        </div>
      </div>
    );
  }
}

// Redux Mapping
const mapStateToProps = (state) => ({
  windowPageYOffset: state.AppReducer.windowPageYOffset,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Header));
