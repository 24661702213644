import React, { Component } from 'react';
import { withRouter } from 'react-router';

import './MenuNavMobile.scss';
import Content from '../Content';
import CaretLeft from '../images/caret-left.svg';
import CaretRight from '../images/caret-right.svg';

class MenuNavMobile extends Component {
  static defaultProps = {}

  // Lifecycle
  constructor(props) {
    super(props);
    this.state = {
      activeSection: this._getActiveSection(props.location.hash),
    };

    // this binding
    this._changeToSection = this._changeToSection.bind(this);
    this._prevSection = this._prevSection.bind(this);
    this._nextSection = this._nextSection.bind(this);
    this._getActiveSection = this._getActiveSection.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location !== nextProps.location)
      return true;
    return false;
  }
  render() {
    const { sections } = Content.Menu;
    const { activeSection } = this.state;

    return (
      <div className={'MenuNavMobile'}>
        <div className='bar'>
          <button className='left' onClick={this._prevSection}>
            <img src={CaretLeft} alt='previous'/>
          </button>

          <div className='labels'>
            <h2>{sections[activeSection].name}</h2>
            {sections[activeSection].subheading &&
              <div className='subheading'>{sections[activeSection].subheading}</div>
            }
          </div>

          <button className='right' onClick={this._nextSection}>
            <img src={CaretRight} alt='previous'/>
          </button>
        </div>
      </div>
    );
  }

  // Events
  _changeToSection(sectionIndex) {
    const { history } = this.props;

    this.setState({ activeSection: sectionIndex });
    history.replace('/menu#'+Content.Menu.sections[sectionIndex].id);
  }
  _prevSection() {
    const { activeSection } = this.state;

    if (activeSection === 0) {
      this._changeToSection(Content.Menu.sections.length - 1);
    }
    else {
      this._changeToSection(activeSection - 1);
    }
  }
  _nextSection() {
    const { activeSection } = this.state;

    this._changeToSection((activeSection + 1) % Content.Menu.sections.length);
  }

  // Utils
  _getActiveSection(hash) {
    const { sections } = Content.Menu;

    for (let i = 0; i < sections.length; i++) {
      if (hash === '#'+sections[i].id) {
        return i;
      }
    }
    return 0;
  }
}
export default withRouter(MenuNavMobile);
