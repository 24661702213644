import React, { Component } from 'react';
import { Switch, Redirect, Route, withRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import AppActions from './redux/actions/AppActions';
import AppReference from './utils/AppReference';
import Breakpoints from './utils/Breakpoints';

import './App.scss';
import Preloader from './components/Preloader';
import Header from './components/Header';
import Nav from './components/Nav';
import Home from './pages/Home';
import Menu from './pages/Menu';
import ModalNotice from './components/modals/ModalNotice';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      preloading: true,
    };

    // Variables
    this.hashOnScrollUpdating = false;
    this.hashOnScrollUpdateAnchors = [];
    this.hashReplaced = false;

    // this binding
    this.setHashUpdateOnScrollAnchors = this.setHashUpdateOnScrollAnchors.bind(this);
    this._locationChanged = this._locationChanged.bind(this);
    this._checkHashUpdateOnScroll = this._checkHashUpdateOnScroll.bind(this);
    this._replaceHash = this._replaceHash.bind(this);
    this._scrollToAnchor = this._scrollToAnchor.bind(this);
    this._onWindowResize = this._onWindowResize.bind(this);
    this._onWindowScroll = this._onWindowScroll.bind(this);

    // Initial
    AppReference.set(this);
    this._onWindowResize();
    this._onWindowScroll();
  }
  componentDidMount() {
    this.hashOnScrollUpdating = true;

    setTimeout(() => {
      if (typeof window.__PRERENDER_INJECTED === 'undefined') {
        this.setState({ preloading: false });
      }
    }, 100);

    // Listeners
    window.addEventListener('resize', this._onWindowResize);
    window.addEventListener('scroll', this._onWindowScroll);
  }
  componentWillUnmount() {
    // Listeners
    window.removeEventListener('resize', this._onWindowResize);
    window.removeEventListener('scroll', this._onWindowScroll);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname
    || this.props.location.hash !== prevProps.location.hash) {
      this._locationChanged(this.props.location, prevProps.location);
    }
  }
  render() {
    const { location } = this.props;
    const { preloading } = this.state;

    return (
      <div className='App'>
        <Preloader preloading={preloading} />
        <div className='navigation'>
          <Header />
          <Nav />
        </div>
        <div className='content'>
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              classNames="fade"
              timeout={500}
            >
              <Switch location={location}>
                <Route path='/menu'>
                  <Menu />
                </Route>
                <Route path='/'>
                  <Home />
                </Route>
                <Route path='*'>
                  <Redirect to='/' />
                </Route>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </div>
        <ModalNotice />
      </div>
    );
  }

  // Actions
  setHashUpdateOnScrollAnchors(anchors) {
    this.hashOnScrollUpdateAnchors = anchors;
  }

  // Utils
  _locationChanged(location, oldLocation) {
    // If we just replaced the hash then stop
    if (this.hashReplaced) {
      this.hashReplaced = false;
      return;
    }

    // If we have a page change
    if (location.pathname !== oldLocation.pathname) {
      this.hashOnScrollUpdating = false;

      window.gtag('config', 'UA-172036167-1');

      setTimeout(() => {
        this._scrollToAnchor(location.hash);

        setTimeout(() => {
          this.hashOnScrollUpdating = true;
        }, 250);
      }, 250);
    }
    else { // Nav to same page
      this._scrollToAnchor(location.hash);
    }
  }
  _checkHashUpdateOnScroll() {
    const { location } = this.props;
    let anchors = this.hashOnScrollUpdateAnchors;

    for (var i = 0; i < anchors.length; i++) {
      let el = document.getElementById(anchors[i]);

      if (el) {
        let top = el.getBoundingClientRect().top;
        if (-100 < top && top < 200) {
          if (location.hash !== '#'+anchors[i]) {
            this._replaceHash(anchors[i]);
          }
          return;
        }
      }
    }
  }
  _replaceHash(hash) {
    const { location, history } = this.props;
    this.hashReplaced = true;

    if (hash.length > 0)
      history.replace(location.pathname+'#'+hash);
    else
      history.replace(location.pathname);
  }
  _scrollToAnchor(anchor) {
    anchor = anchor.replace('#','');
    const { location, breakpoint } = this.props;
    const isMobile = !Breakpoints.isDesktop(breakpoint);

    // Menu page on mobile should always go to the top
    if (location.pathname === '/menu' && isMobile) {
      window.scroll(0,0);
      return;
    }

    // Scrolling to a section
    let el = document.getElementById(anchor);
    if (el) {
      let y = el.getBoundingClientRect().top + window.scrollY;

      if (isMobile) {
        y -= 83; // Home Mobile header nav bar height
      }
      if (location.pathname === '/menu') {
        y -= 83; // Menu desktop flags height
      }

      window.scroll(0,y);
    }
    else {
      window.scroll(0,0);
    }
  }

  // Events
  _onWindowResize() {
    this.props.setWindowDimensions(window.innerWidth, window.innerHeight);
  }
  _onWindowScroll() {
    this.props.setWindowScrollOffset(window.pageYOffset);

    if (this.hashOnScrollUpdating) {
      this._checkHashUpdateOnScroll();
    }
  }
}

// Redux Mapping
const mapStateToProps = (state) => ({
  breakpoint: state.AppReducer.breakpoint,
});
const mapDispatchToProps = (dispatch) => ({
  setWindowDimensions: (w, h) => {
    dispatch( AppActions.setWindowDimensions(w, h) );
  },
  setWindowScrollOffset: (yOffset) => {
    dispatch( AppActions.setWindowScrollOffset(yOffset) );
  },
});
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(App))
