import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import getUniqueId from '../../utils/getUniqueId'
import './Modal.scss';

ReactModal.setAppElement('#root');

Modal.defaultProps = {
  children: null,
  isOpen: false,
};

export default function Modal(props) {
  const {
    children,
    isOpen,
  } = props;

  const [id, setId] = useState(null);

  useEffect(() => {
    if (typeof window.__PRERENDER_INJECTED === 'undefined') {
      if (isOpen) {
        setTimeout(() => {
          let contentEl = document.getElementById(id);
          if (contentEl)
            disableBodyScroll(contentEl);
        }, 100);
      }
      else {
        let contentEl = document.getElementById(id);
        if (contentEl)
          enableBodyScroll(contentEl);
      }
    }
  }, [id, isOpen]);

  useEffect(() => {
    setId(getUniqueId('modal'));
  }, []);

  return (
    <div>
      {typeof window.__PRERENDER_INJECTED === 'undefined' &&
        <ReactModal id={id}
          isOpen={isOpen}
          closeTimeoutMS={600}
          portalClassName={"modal-portal"}
          overlayClassName={"modal-overlay"}
          className={"modal-content"}
        >
          {children}
        </ReactModal>
      }
    </div>
  );
}
