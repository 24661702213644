import Breakpoints from '../../utils/Breakpoints';

export default class AppActions {
  static setWindowDimensions(windowWidth, windowHeight) {
    return {
      type: 'SET_WINDOW_DIMENSIONS',
      breakpoint: Breakpoints.getBreakpoint(),
      windowWidth: windowWidth,
      windowHeight: windowHeight,
    };
  }
  static setWindowScrollOffset(windowPageYOffset) {
    return {
      type: 'SET_WINDOW_SCROLL_OFFSET',
      windowPageYOffset: windowPageYOffset,
    };
  }

}
