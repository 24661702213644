import React, { Component } from 'react';
import { withRouter } from 'react-router';
import NavLink from './NavLink';

import './MenuNavDesktop.scss';
import Content from '../Content';

class MenuNavDesktop extends Component {
  static defaultProps = {}

  // Lifecycle
  constructor(props) {
    super(props);
    this.state = {};

    // this binding
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location !== nextProps.location)
      return true;
    return false;
  }
  render() {
    const { sections } = Content.Menu;

    let items = [];
    for (var i = 0; i < sections.length; i++) {
      let name = sections[i].name;
      items.push(
        <li key={'menu-nav-'+i}>
          <NavLink to={'/menu#'+sections[i].id} scrollYOffset={-84} onClick={(e) => {
            window.gtag('event', 'Click', {
              'event_category' : 'Menu Navigation',
              'event_label' : name,
            });
          }}>
            {name}
          </NavLink>
        </li>
      );
    }

    return (
      <div className={'MenuNavDesktop'}>
        <div className='fixed'>
          <ul>
            {items}
          </ul>
        </div>
      </div>
    );
  }
}
export default withRouter(MenuNavDesktop);
