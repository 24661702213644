import React, { Component } from 'react';

import './SectionHome.scss';

export default class SectionHome extends Component {
  render() {
    const { children } = this.props;

    return (
      <div className='SectionHome'>
        {children}
      </div>
    );
  }
}
