import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppReference from '../utils/AppReference';
import Breakpoints from '../utils/Breakpoints';

import './Menu.scss';
import Content from '../Content';
import MetaTags from '../components/MetaTags';
import MenuFlags from '../components/MenuFlags';
import MenuNavMobile from '../components/MenuNavMobile';
import MenuNavDesktop from '../components/MenuNavDesktop';
import ButtonReservationBar from '../components/ButtonReservationBar';
import Footer from '../components/Footer';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // Variables
    this.app = AppReference.get();

    // this binding
    this._getContent = this._getContent.bind(this);
  }
  componentDidMount() {
    const { sections } = Content.Menu;
    let anchors = [];

    for (var i = 0; i < sections.length; i++) {
      anchors.push(sections[i].id);
    }

    this.app.setHashUpdateOnScrollAnchors(anchors);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.breakpoint !== nextProps.breakpoint
      || this.props.location !== nextProps.location) {
      return true;
    }
    return false;
  }
  render() {
    const { Menu } = Content;
    const { breakpoint, location } = this.props;
    const isMobile = !Breakpoints.isDesktop(breakpoint);

    if (isMobile) {
      return (
        <div className='Menu'>
          <MetaTags meta={Menu.meta} />
          <MenuNavMobile />
          <div className="menu-header-pad"></div>
          <MenuFlags />
          <div className='sections'>
            {this._getContent(location.hash)}
            <div className="section">
              <div className='note'>
                *Consuming raw or undercooked meats, poultry, seafood, shellfish or eggs may increase your risk of food born illnesses.
              </div>
            </div>
          </div>
          <ButtonReservationBar />
          <Footer />
        </div>
      );
    }
    else {
      return (
        <div className='Menu'>
          <MetaTags meta={Menu.meta} />
          <MenuFlags />
          <div className='menu-side-by-side'>
            <div className='menu-nav'>
              <MenuNavDesktop />
            </div>
            <div className='sections'>
              {this._getContent(location.hash)}
              <div className="section">
                <div className='note'>
                  *Consuming raw or undercooked meats, poultry, seafood, shellfish or eggs may increase your risk of food born illnesses.
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }
  }

  // Utils
  _getContent(hash) {
    const { sections } = Content.Menu;
    const { breakpoint } = this.props;

    const isMobile = !Breakpoints.isDesktop(breakpoint);

    if (isMobile) {
      for (let i = 0; i < sections.length; i++) {
        if (hash === '#'+sections[i].id || hash === '') {
          return (
            <div id={sections[i].id} className="section">
              {sections[i].content()}
            </div>
          );
        }
      }
    }
    else {
      let content = [];
      for (let i = 0; i < sections.length; i++) {
        content.push(
          <div id={sections[i].id} className="section" key={'section-'+i}>
            <div className='heading'>
              <h2>{sections[i].name}</h2>
              {sections[i].subheading &&
                <div className="subheading">{sections[i].subheading}</div>
              }
            </div>
            {sections[i].content()}
          </div>
        );
      }
      return content;
    }
  }
}

// Redux Mapping
const mapStateToProps = (state) => ({
  breakpoint: state.AppReducer.breakpoint,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Menu));
