class AppReference {
  constructor() {
    this._app = null;
  }

  set(a) {
    this._app = a;
  }
  get() {
    return this._app;
  }
}

let appReference = new AppReference();
export default appReference;
