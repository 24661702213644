import React, { Component } from 'react';
import { connect } from 'react-redux';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import Breakpoints from '../utils/Breakpoints';

import './Nav.scss';
import Content from '../Content';
import NavLink from './NavLink';
import NavHamburger from './NavHamburger';
import ButtonReservationBar from './ButtonReservationBar';
import ButtonSocialBar from './ButtonSocialBar';
import Footer from './Footer';

class Nav extends Component {
  static defaultProps = {}

  // Lifecycle
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };

    // this binding
    this._toggleNav = this._toggleNav.bind(this);
    this._closeNav = this._closeNav.bind(this);
  }
  render() {
    const { Logo, Menu } = Content;
    const { breakpoint } = this.props;
    const { active } = this.state;

    const isMobile = !Breakpoints.isDesktop(breakpoint);

    return (
      <div className={'Nav'+(active ? ' active' : '')}>
        {isMobile &&
          <NavHamburger isActive={active} onClick={this._toggleNav} />
        }
        {isMobile &&
          <nav className={'mobile' + (active ? ' active' : '')}>
            <div className='scroll-area'>
              <NavLink className='logo' to='/' onClick={(e) => { this._closeNav(); this._navSelectEvent('Logo'); }}>
                <img src={Logo.src} alt={Logo.alt} />
              </NavLink>
              <ul>
                <li>
                  <NavLink to='/' onClick={(e) => { this._closeNav(); this._navSelectEvent('Home'); }}>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/#about' onClick={(e) => { this._closeNav(); this._navSelectEvent('About'); }}>
                    About
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/#reviews' onClick={(e) => { this._closeNav(); this._navSelectEvent('Reviews'); }}>
                    Reviews
                  </NavLink>
                </li>
                <li>
                  <NavLink to={'/menu#'+Menu.sections[0].id} onClick={(e) => { this._closeNav(); this._navSelectEvent('Menu'); }} ignoreHashForActive={true}>
                    Menu
                  </NavLink>
                </li>
                <li>
                  <NavLink to='/#location-and-hours' onClick={(e) => { this._closeNav(); this._navSelectEvent('Location & Hours'); }}>
                    Location & Hours
                  </NavLink>
                </li>
              </ul>
              <ButtonReservationBar paddingVertical='24px' />
              <Footer />
            </div>
          </nav>
        }
        {!isMobile &&
          <nav className='desktop'>
            <NavLink className='logo' to="/" onClick={(e) => { this._navSelectEvent('Logo'); }}>
              <img src={Logo.src} alt={Logo.alt} />
            </NavLink>
            <ul>
              <li>
                <NavLink to='/#about' onClick={(e) => { this._navSelectEvent('About'); }}>
                  About
                </NavLink>
              </li>
              <li>
                <NavLink to='/#reviews' onClick={(e) => { this._navSelectEvent('Reviews'); }}>
                  Reviews
                </NavLink>
              </li>
              <li>
                <NavLink to={'/menu#'+Menu.sections[0].id} onClick={(e) => { this._navSelectEvent('Menu'); }} ignoreHashForActive={true}>
                  Menu
                </NavLink>
              </li>
              <li>
                <NavLink to='/#location-and-hours' onClick={(e) => { this._navSelectEvent('Location & Hours'); }}>
                  Location & Hours
                </NavLink>
              </li>
            </ul>

            <ButtonReservationBar paddingVertical='24px' />
            <ButtonSocialBar/>
          </nav>
        }
      </div>
    );
  }

  // Actions
  _toggleNav(e) {
    let newActive = !this.state.active;
    this.setState({ active: newActive });

    if (newActive)
      setTimeout(() => {
        let contentEl = document.querySelector('.scroll-area');
        if (contentEl)
          disableBodyScroll(contentEl);
      }, 100);
    else {
      let contentEl = document.querySelector('.scroll-area');
      if (contentEl)
        enableBodyScroll(contentEl);
    }
  }
  _closeNav(e) {
    this.setState({ active: false });

    let el = document.querySelector('.scroll-area');
    enableBodyScroll(el);
  }
  _navSelectEvent(linkName) {
    window.gtag('event', 'Click', {
      'event_category' : 'Navigation',
      'event_label' : linkName,
    });
  }
}

// Redux Mapping
const mapStateToProps = (state) => ({
  breakpoint: state.AppReducer.breakpoint,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps,mapDispatchToProps)(Nav);
