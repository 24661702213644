import React, { Component } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';

export default class NavLink extends Component {
  static defaultProps = {
    to: '/',
    className: null,
    onClick: null,
    ignoreHashForActive: false,
  }

  // Lifecycle
  constructor(props) {
    super(props);
    this.state = {};

    // this binding
  }
  render() {
    const { to, className, onClick, ignoreHashForActive } = this.props;

    return (
      <RRNavLink
        to={to}
        className={className || ''}
        activeClassName='active'
        isActive={(match, location) => {
          if (!match)
            return false;

          if (ignoreHashForActive) {
            return (to.indexOf(location.pathname) >= 0);
          }

          let pathHash = location.pathname + location.hash;
          return (pathHash.indexOf(to) >= 0);
        }}
        onClick={onClick}
      >
        {this.props.children}
      </RRNavLink>
    );
  }
}
