import React, { Component } from 'react';
import { connect } from 'react-redux';
import Breakpoints from '../utils/Breakpoints';
import Fade from 'react-reveal/Fade';

import './HomeAbout.scss';
import Content from '../Content';
import SectionHome from './SectionHome';
import SectionHomeHeader from './SectionHomeHeader';

class HomeAbout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fadeIn: false,
    };
  }
  render() {
    const { breakpoint } = this.props;
    const { fadeIn } = this.state;
    const { title, subheading, descriptionHTML } = Content.Home.About;

    const headerTheme = (Breakpoints.isDesktop(breakpoint) ? 'on-light' : 'on-dark');

    return (
      <div {...this.props} className='HomeAbout'>
        <SectionHome>
          <SectionHomeHeader
            h1={title}
            subheading={subheading}
            theme={headerTheme}
            onAnimationDone={() => this.setState({ fadeIn: true })}
          />

          <Fade top distance='20px' when={fadeIn} duration={600}>
            <div className="desc" dangerouslySetInnerHTML={{ __html: descriptionHTML}}></div>
          </Fade>
        </SectionHome>
      </div>
    );
  }
}

// Redux Mapping
const mapStateToProps = (state) => ({
  breakpoint: state.AppReducer.breakpoint,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps,mapDispatchToProps)(HomeAbout);
