import React, { Component } from 'react';
import RMT from 'react-meta-tags';

export default class MetaTags extends Component {
  static defaultProps = {
    meta: {
      title: 'Title goes here',
      description: 'Description goes here',
      image: {
        src: '/someimage.jpg',
        alt: 'Image alt text',
        width: 100,
        height: 100,
      }
    },
  }

  render() {
    const { meta } = this.props;

    return (
      <RMT>
        <title>{meta.title}</title>
        <meta name="description" content={meta.title} />
        <meta property="og:title" content={meta.description} />
        <meta property="og:description" content={meta.description} />
        {meta.image &&
          <meta property="og:image" content={meta.image.src} />
        }
        {meta.image &&
          <meta property="og:image:alt" content={meta.image.alt} />
        }
        {meta.image &&
          <meta property="og:image:width" content={meta.image.width} />
        }
        {meta.image &&
          <meta property="og:image:height" content={meta.image.height} />
        }
      </RMT>
    );
  }
}
