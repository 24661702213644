import React, { Component } from 'react';

import './ButtonSocial.scss';

export default class ButtonSocial extends Component {
  static defaultProps = {
    icon: null,
    iconWidth: 0,
    iconHeight: 0,
    alt: '',
    link: 'https://example.com',
    onClick: null,
  }

  render() {
    const { icon, iconWidth, iconHeight, alt, link, onClick } = this.props;

    return (
      <a className='ButtonSocial' href={link} target='_blank' rel="noopener noreferrer" onClick={onClick}>
        <img src={icon} width={iconWidth} height={iconHeight} alt={alt} />
      </a>
    );
  }
}
