import React, { Component } from 'react';
import { connect } from 'react-redux';
import Breakpoints from '../utils/Breakpoints';
import Fade from 'react-reveal/Fade';

import './HomeLocationsAndHours.scss';
import Content from '../Content';
import SectionHome from './SectionHome';
import SectionHomeHeader from './SectionHomeHeader';

class HomeLocationsAndHours extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fadeInLocation: false,
      fadeInHours: false,
    };
  }
  render() {
    const { breakpoint } = this.props;
    const { fadeInLocation, fadeInHours } = this.state;
    const Location = Content.Home.Location;
    const Hours = Content.Home.Hours;
    const isMobile = !Breakpoints.isDesktop(breakpoint);

    let hoursItems = [];
    for (let i = 0; i < Hours.list.length; i++) {
      if (isMobile) {
        let parts = Hours.list[i].split(': ');
        hoursItems.push(
          <p key={'hours-'+i}>
            {parts[0]}<br />
            {parts[1]}
          </p>
        );
      }
      else { // Desktop
        hoursItems.push(
          <p key={'hours-'+i}>
            {Hours.list[i]}
          </p>
        );
      }
    }

    return (
      <div {...this.props} className='HomeLocationsAndHours'>
        <SectionHome>
          <SectionHomeHeader
            h1={Location.title}
            subheading={Location.subheading}
            theme={'on-dark'}
            onAnimationDone={() => this.setState({ fadeInLocation: true })}
          />

          <Fade top distance='20px' when={fadeInLocation} duration={600}>
            <div>
              <p>
                {Location.address}
                <br />
                {Location.cityStateZip}
              </p>

              <a href={Location.mapLink} target="_blank" rel="noopener noreferrer">
                <img src={(isMobile ? Location.mapImageMobile : Location.mapImageDesktop)}
                  alt={Location.mapAlt}
                />
              </a>
            </div>
          </Fade>


          <SectionHomeHeader
            h1={Hours.title}
            theme={'on-dark'}
            onAnimationDone={() => this.setState({ fadeInHours: true })}
          />
          <Fade top distance='20px' when={fadeInHours} duration={600}>
            <div>
              {hoursItems}
            </div>
          </Fade>

        </SectionHome>
      </div>
    );
  }
}

// Redux Mapping
const mapStateToProps = (state) => ({
  breakpoint: state.AppReducer.breakpoint,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps,mapDispatchToProps)(HomeLocationsAndHours);
