import React, { Component } from 'react';

import './Footer.scss';
import Content from '../Content';

export default class Footer extends Component {
  render() {
    const {
      copyrightBy,
      author,
      authorHref,
    } = Content.Footer;

    const nowYear = new Date().getFullYear();

    return (
      <div className='Footer'>
        <div className='copyright'>
          Site contents © 2020 - {nowYear} {copyrightBy}
        </div>
        <a href={authorHref} target="_blank" rel="noopener noreferrer" className='author'>
          {author}
        </a>
      </div>
    );
  }
}
