import React, { Component } from 'react';

import './Review.scss';

import Star from '../images/reviews/star.svg';

export default class Review extends Component {
  static defaultProps = {
    rating: 0,
    text: null,
    secondReview: false,
    secondRating: 0,
    secondText: null,
  }

  // Lifecycle
  constructor(props) {
    super(props);

    this.state = {};

    // this binding
    this._getRating = this._getRating.bind(this);
  }

  render() {
    const { rating, text,secondReview, secondRating, secondText } = this.props;

    return (
      <div className='Review'>
        <div className="rating">
          {this._getRating(rating)}
        </div>
        <p>"{text}"</p>
        {secondReview &&
          <div className="second">
            <div className="rating">
              {this._getRating(secondRating)}
            </div>
            <p>"{secondText}"</p>
          </div>
        }
      </div>
    );
  }

  // Utils
  _getRating(rating) {
    let stars = [];

    for(let i = 0;i < rating;i ++) {
      stars.push(
        <div key={'star-'+i}>
          <img src={Star} alt={'Star'} />
        </div>
      )
    }

    return stars;
  }
}
