import React, { Component } from 'react';
import './HomeHeader.scss';
import Content from '../Content';

export default class HomeHeader extends Component {
  render() {
    const { Logo } = Content;

    return (
      <div className={'HomeHeader'}>
        <img className='logo' src={Logo.src} alt={Logo.alt} />
      </div>
    );
  }
}
