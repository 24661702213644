import React, { Component } from 'react';
import { connect } from 'react-redux';
import Breakpoints from '../utils/Breakpoints';
import Fade from 'react-reveal/Fade';

import './HomeMenu.scss';
import CaretRight from '../images/caret-right.svg';
import Content from '../Content';
import SectionHome from './SectionHome';
import SectionHomeHeader from './SectionHomeHeader';
import NavLink from './NavLink';

class HomeMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fadeIn: false,
    };
  }
  render() {
    const { breakpoint } = this.props;
    const { fadeIn } = this.state;
    const { title, sections } = Content.Menu;
    const isMobile = !Breakpoints.isDesktop(breakpoint);

    let items = [];
    for (var i = 0; i < sections.length; i++) {
      items.push(
        <NavLink key={'menu-section-'+i} to={'/menu#'+sections[i].id}
          className={(isMobile ? 'mobile' : 'desktop')}
        >
          {sections[i].name}
          <span>
            <img src={CaretRight} alt={'View'} />
          </span>
        </NavLink>
      );
    }

    return (
      <div {...this.props} className='HomeMenu'>
        <SectionHome>
          <SectionHomeHeader
            h2={title}
            bottomLine={!isMobile}
            theme={'on-light'}
            onAnimationDone={() => this.setState({ fadeIn: true })}
          />

          <Fade top distance='20px' when={fadeIn} duration={600}>
            <div className='sections'>
              {items}
            </div>
          </Fade>
        </SectionHome>
      </div>
    );
  }
}

// Redux Mapping
const mapStateToProps = (state) => ({
  breakpoint: state.AppReducer.breakpoint,
});
const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps,mapDispatchToProps)(HomeMenu)
