import React, { Component } from 'react';
import Fade from 'react-reveal/Fade';

import './SectionHomeHeader.scss';

export default class SectionHomeHeader extends Component {
  static defaultProps = {
    h1: null,
    h2: null,
    subheading: null,
    theme: 'on-dark',  // 'on-dark', 'on-light'
    onAnimationDone: null,
  }

  constructor(props) {
    super(props);

    this.state = {
      fadeHeading: false,
      fadeSubheading: false,
    };

    // Variables
    this.timeout = null;
  }
  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
  render() {
    const { h1, h2, subheading, theme, onAnimationDone } = this.props;
    const { fadeHeading, fadeSubheading } = this.state;
    const heading = (h1 ? <h1>{h1}</h1> : <h2>{h2}</h2>);

    return (
      <div className={'SectionHomeHeader '+theme}>
        <Fade top distance='20px' when={fadeHeading} duration={600}>
          {heading}
        </Fade>

        {subheading &&
          <Fade top distance='20px' when={fadeSubheading} duration={600}>
            <div className='subheading'>{subheading}</div>
          </Fade>
        }

        <Fade top distance='100px' duration={1000}
          onReveal={() => {
            this.timeout = setTimeout(() => {
              this.setState({ fadeHeading: true });

              this.timeout = setTimeout(() => {
                this.setState({ fadeSubheading: true });

                this.timeout = setTimeout(() => {
                  if (onAnimationDone)
                    onAnimationDone();
                }, 600);
              }, 200);
            }, 200);
          }}
        >
          <div className='line'></div>
        </Fade>
      </div>
    );
  }
}
