import React, { Component } from 'react';

import './MenuFlags.scss';
import Content from '../Content';

export default class MenuFlags extends Component {
  render() {
    const { flags } = Content.Menu;

    let items = [];
    for (var i = 0; i < flags.length; i++) {
      items.push(
        <div key={'flag-'+i} className='item'>
          <img src={flags[i].icon} alt={flags[i].name} />
          <span>{flags[i].name}</span>
        </div>
      );
    }

    return (
      <div className='MenuFlags'>
        {items}
      </div>
    );
  }
}
